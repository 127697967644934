import * as singleSpa from 'single-spa'; // waiting for this to be merged: https://github.com/CanopyTax/single-spa/pull/156

export async function loadApp(name, activityFn, appURL, storeURL, globalEventDistributor) {
    // let storeModule = {}, customProps = {globalEventDistributor: globalEventDistributor};
    let customProps = globalEventDistributor;

    // // try to import the store module
    // try {
    //     storeModule = storeURL ? await System.import(storeURL).then(module => module.default) : {storeInstance: null};
    // } catch (e) {
    //     console.log(`Could not load store of app ${name}.`, e);
    // }

    // if (storeModule.storeInstance && globalEventDistributor) {
    //     // add a reference of the store to the customProps
    //     customProps.store = storeModule.storeInstance;

    //     // register the store with the globalEventDistributor
    //     globalEventDistributor.registerStore(storeModule.storeInstance);
    // }

    // register the app with singleSPA and pass a reference to the store of the app as well as a reference to the globalEventDistributor
    //It shuld register only one per app 
    try {
        singleSpa.registerApplication(
            name,
            () => System.import(appURL).then(module => module.default),
            activityFn,
            customProps
        );
    } catch (error) {
    }
    
    
}