var userRoutes = [
  "/login",
  `/password/email`,
  `/members`,
  "/password/reset/",
  "/security/token",
  "/demo"
];

var accountRoutes = [
  "/home",
  "/reports",
  "/dashboard",
  "/servers",
  "/components",
  "/members",
  "/registration",
  "/welcome",
  "/news",
  "/custom-indicators",
  "/component/",
  "/configuration/assistance",
  "/configuration/quality",
  "/configuration/ci",
  "/configuration/ci/apptypes",
  "/configuration/application",
  "/okrs",
  "/okr/create",
  "/okr/edit/",
  "/security/token",
  "/formeditscserver",
  "/configuration/sc",
  "/configuration/qaext",
  "/login",
  "/password/email",
  "/configuration/hierarchy",
  "/finish",
  "/invoicing",
  "/newidea",
  "/usage-server"
];

var monolithRoutes = [
  "/home",
  "/dashboard",
  "/servers",
  `/components`,
  "/reports",
  `/members`,
  `/welcome`,
  "/logout",
  "/configuration/quality",
  "/configuration/ci",
  "/configuration/ci/apptypes",
  "/component/",
  "/custom-indicators",
  "/configuration/application",
  "/configuration/hierarchy",
  "/okrs",
  "/okr/create",
  "/okr/edit/",
  "/security/token",
  "/formeditscserver",
  "/configuration/sc",
  "/configuration/qaext",
  "/invoicing",
  "/newidea",
  "/usage-server",
];

var componentsRoutes = [
  "/configuration/application",
  '/components',
];

var okrEditRoutes = [];

var vueRoutes = [
  "/configuration/hierarchy"
];

var angularRoutes = [
  "/custom-indicators",
  "/okrs",
  "/okr/create",
  "/okr/edit/",
];

function checkPathName(location, routesArray) {
  return routesArray.some((route) => location.pathname.startsWith(route));
}

export function userActivityFn() {
  return (location) =>
    location.pathname === "/" || checkPathName(location, userRoutes);
}

export function appActivityFn(menuState) {
  menuState.forEach((element) => {
    monolithRoutes.push("/" + element);
  });

  return (location) =>
    checkPathName(location, monolithRoutes) ||
    !checkPathName(location, userRoutes);
}

export function accountActivityFn(menuState) {
  menuState.forEach((element) => {
    accountRoutes.push("/" + element);
  });

  return (location) =>
    checkPathName(location, accountRoutes) ||
    (!checkPathName(location, userRoutes) &&
      !checkPathName(location, vueRoutes) &&
      !location.pathname === "/");
}

export function componentsActivityFn(menuState) {
  menuState.forEach((element) => {
    componentsRoutes.push("/" + element);
  });

  return (location) =>
    (!checkPathName(location, userRoutes) &&
      !checkPathName(location, accountRoutes) &&
      !checkPathName(location, vueRoutes) &&
      !location.pathname === "/") ||
    checkPathName(location, componentsRoutes);
}

export function okrEditActivityFn(menuState) {
  menuState.forEach((element) => {
    okrEditRoutes.push("/" + element);
  });

  return (location) =>
    (!checkPathName(location, userRoutes) &&
      !checkPathName(location, accountRoutes) &&
      !checkPathName(location, vueRoutes) &&
      !location.pathname === "/") ||
    checkPathName(location, okrEditRoutes);
}

export function vueActivityFn() {
  return (location) => checkPathName(location, vueRoutes);
}

export function angularActivityFn() {
  return (location) => checkPathName(location, angularRoutes);
}
