import * as singleSpa from "single-spa";
import { loadApp } from "./helper";
import {
  userActivityFn,
  appActivityFn,
  accountActivityFn,
  componentsActivityFn,
  vueActivityFn,
  angularActivityFn,
  okrEditActivityFn,
} from "./util/spasActivityFuntion";
import { props as userProps } from "./util/properties/user.properties";
import { props as appProps } from "./util/properties/app.properties";
import { getJsonObject } from "./blocks/storage/storage";

function isUserLogin() {
  if (window.localStorage.getItem("croot")) {
    return true;
  }
  return false;
}

async function loadMfAfterLoginListener(onlyOnceLoad) {
  const loadingPromises = [];

  if (onlyOnceLoad == 0) {
    window.addEventListener("single-spa:before-routing-event", (evt) => {
      if (isUserLogin()) {
        let decipherText = getJsonObject("menu_states");
        if (onlyOnceLoad == 0) {
          loadingPromises.push(
            loadApp(
              "app",
              appActivityFn(decipherText),
              "/app/js/singleSpaEntry.js",
              null,
              appProps
            )
          );
          loadingPromises.push(
            loadApp(
              "mfaccount",
              accountActivityFn(decipherText),
              "/mfaccount/singleSpaEntry.js",
              null,
              null
            )
          );
          loadingPromises.push(
            loadApp(
              "mfcomponents",
              componentsActivityFn(decipherText),
              "/mfcomponents/singleSpaEntry.js",
              null,
              null
            )
          );
          onlyOnceLoad++;
        }
      }
    });
  }
  return onlyOnceLoad;
}

async function secureRoutesAfterLogin(onlyOnceLoad) {
  const loadingPromises = [];

  let userHierarchy = getJsonObject("menu_states");
  var componentUrl = /^\/component\/\d{1,5}$/;
  var okrEditUrl = /^\/okr\/edit\/[0-9a-fA-F]{24}$/;
  let secureRoutes = [
    "/home",
    "/dashboard",
    "/servers",
    "/reports",
    "/test",
    "/news",
    "/components",
    "/members",
    "/welcome",
    "/configuration/assistance",
    "/configuration/hierarchy",
    "/configuration/quality",
    "/configuration/ci",
    "/configuration/ci/apptypes",
    "/configuration/application",
    "/custom-indicators",
    "/okrs",
    "/okr/create",
    "/security/token",
    "/formeditscserver",
    "/configuration/sc",
    "/configuration/qaext",
    "/finish",
    "/invoicing",
    "/newidea",
    "/usage-server"
  ];
  userHierarchy.forEach((element) => {
    secureRoutes.push("/" + element);
  });
  const addDynamicRoutes = secureRoutes.filter(
    (word) => word == location.pathname
  );

  if (
    addDynamicRoutes.length > 0 ||
    componentUrl.test(location.pathname) ||
    okrEditUrl.test(location.pathname)
  ) {
    if (onlyOnceLoad == 0) {
      loadingPromises.push(
        loadApp(
          "app",
          appActivityFn(userHierarchy),
          "/app/js/singleSpaEntry.js",
          null,
          appProps
        )
      );
      loadingPromises.push(
        loadApp(
          "mfaccount",
          accountActivityFn(userHierarchy),
          "/mfaccount/singleSpaEntry.js",
          null,
          null
        )
      );
      loadingPromises.push(
        loadApp(
          "mfcomponents",
          componentsActivityFn(userHierarchy),
          "/mfcomponents/singleSpaEntry.js",
          null,
          null
        )
      );
      loadingPromises.push(
        loadApp(
          "mfangular",
          okrEditActivityFn(userHierarchy),
          "/mfangular/main.js",
          null,
          null
        )
      );


      onlyOnceLoad++;
    }
  } else {
    location.assign("/login");
  }
  return onlyOnceLoad;
}

async function loadMfAfterLoginRefresh(onlyOnceLoad) {
  const loadingPromises = [];
  var routesWithOutLocationData = [
    "/login",
    `/password/email`,
    "/registration",
    "/password/reset/",
    "/test",
    "/logout",
    "/demo"
  ];

  if (!isUserLogin()) {
    if (location.pathname != "/login") {
      const result = routesWithOutLocationData.find((word) => word == location.pathname);
      if (result == "/registration" || result == "/password/email") {
        let decipherText = [];
        loadingPromises.push(
          loadApp(
            "mfaccount",
            accountActivityFn(decipherText),
            "/mfaccount/singleSpaEntry.js",
            null,
            null
          )
        );
      } else if (!result) {
        location.assign("/login");
      }
    } else {
      if (location.pathname == "/login") {
        loadingPromises.push(
          loadApp(
            "mfaccount"
            , accountActivityFn([])
            , "/mfaccount/singleSpaEntry.js"
            , null
            , null
          )
        );
      }
    }
  } else {
    if (location.pathname == "/login") {
      if (isUserLogin()) {
        location.assign("/home");
      } else {
        location.assign("/login");
      }
    } else {
      onlyOnceLoad = await secureRoutesAfterLogin(onlyOnceLoad);
    }
  }
  return onlyOnceLoad;
}

async function init() {
  const loadingPromises = [];
  var onlyOnceLoad = 0;

  singleSpa.start();

  await loadingPromises.push(
    loadApp(
      "mfuser",
      userActivityFn(),
      "/mfuser/js/singleSpaEntry.js",
      null,
      userProps
    )
  );

  await loadingPromises.push(
    loadApp("mfvue", vueActivityFn(), "/mfvue/singleSpaEntry.js", null, null)
  );
  await loadingPromises.push(
    loadApp("mfangular", angularActivityFn(), "/mfangular/main.js", null, null)
  );

  onlyOnceLoad = await loadMfAfterLoginListener(onlyOnceLoad);
  await loadMfAfterLoginRefresh(onlyOnceLoad);

  await Promise.all(loadingPromises);
}

init();
